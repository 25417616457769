import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getReferral } from 'store/referral/referral.action';

export default function useReferral() {
    const dispatch = useDispatch();
    const { data, error, isLoading } = useSelector((state) => state.referralReducer);

    useEffect(
        () => {
            if (isLoading) {
                return;
            }

            // if (isEmpty(data)) {
            dispatch(getReferral());

            // }
        },
        [
            /* data */
        ],
    );

    return { data, error, isLoading };
}
