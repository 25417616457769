import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import taooCoin from 'assets/Images/taoo-coin.png';
import Img from 'components/img/Img';
import Loader from 'components/loaders/loader';
import QRCodeContainer from 'components/widgets/qr-code-container/QRCodeContainer';
import { LoaderInButtonFillColor } from 'constants/defaults';
import { copyTextToClipboard } from 'services/navigator';

import styles from './index.module.scss';

const domain = window.location.origin;

function InviteFriend({ data, isLoading }) {
    const [linkCopied, setLinkCopied] = useState(false);

    const referral = useMemo(() => {
        let code;

        if (!data || !data?.code) {
            code = 'N/A';
        } else code = data?.code;

        return `${ domain }/r/${ code }`;
    }, [data]);

    const copyReferral = useCallback(() => {
        copyTextToClipboard(referral);

        setLinkCopied(true);

        setTimeout(() => {
            setLinkCopied(false);
        }, 1000);
    }, [referral]);

    return (
        <>
            <div className={styles['steps-container-title']}>
                <QRCodeContainer wrapperClassNames={styles.QRCodeContainer} value={referral} />

                <h5>
                    <FormattedMessage id="invite-friend.value-friend-ship" />
                </h5>
                <p>
                    <FormattedMessage id="invite-friend.follow-steps-and-get-rewards" />
                </p>
            </div>
            <div className={styles['steps-container']}>
                <div className={styles.firstItem}>
                    <div>
                        <div className={styles.circle}>
                            <span>1</span>
                        </div>
                        <div className={`${ styles.description } d-flex  align-items-center`}>
                            <FormattedMessage id="invite-friend.share-with-friend" /> <br />
                            <button type="button" disabled={isLoading && !data} onClick={copyReferral}>
                                {isLoading && !data ? (
                                    <Loader fill={LoaderInButtonFillColor} />
                                ) : (
                                    <FormattedMessage
                                        id={linkCopied ? 'invite-friend.link-copied' : 'invite-friend.copy-link'}
                                    />
                                )}
                            </button>
                        </div>
                    </div>
                    <p>
                        <FormattedMessage id="invite-friend.present-qr-code" />
                    </p>
                </div>
                <div className={styles.item}>
                    <div className={styles.circle}>
                        <span>2</span>
                    </div>
                    <div className={styles.description}>
                        <FormattedMessage id="invite-friend.your-friend-using-your-link" />
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.circle}>
                        <span>3</span>
                    </div>
                    <div className={styles.description}>
                        <FormattedMessage id="invite-friend.your-friend-place-an-order" />
                    </div>
                </div>
                <div className={styles['more-details']}>
                    <ul>
                        <li className={`${ styles.get } mb-3`}>
                            <p className={styles.dark}>
                                <FormattedMessage id="invite-friend.get" />
                            </p>
                            <p className="d-flex align-items-center">
                                <span>
                                    <FormattedMessage id="referrer.invite-friend.up-to" />
                                </span>
                                <span className={styles.point_image}>
                                    <Img src={taooCoin} alt="taoo logo" />
                                </span>
                            </p>
                            <p className={styles.dark}>
                                <FormattedMessage id="referrer.invite-friend.points-count" />
                            </p>
                        </li>
                        <li className="mb-3">
                            <p className="text-center" style={{ fontSize: '1.125rem' }}>
                                <FormattedMessage id="referrer.invite-friend.help" />
                            </p>
                        </li>
                    </ul>
                    <div className={styles['more-details-refers']}>
                        <p>
                            <FormattedMessage id="invite-friend.refer-for-extra-rewards" />
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default InviteFriend;
