import { FormattedMessage } from 'react-intl';

import taooCoinColorReversed from 'assets/Images/taoo-coin-color-reversed.png';
import Img from 'components/img/Img';
import Loader from 'components/loaders/loader';
import { generateImageUrl } from 'services/url';

import FriendItem from './friend-item';
import styles from './index.module.scss';

function FriendList({ data, isLoading }) {
    const people = data?.referrals;

    return (
        <>
            <div className={`mt-4 ${ styles['invite-history-container'] }`}>
                <h6 className="mb-3">
                    <FormattedMessage id="referal.your-invite-history" />
                </h6>
                <div className="d-flex align-items-center col-gap-10">
                    <div className="flex-grow-1">
                        <div className="d-flex align-items-center col-gap-5">
                            <span className={styles.num}>{data?.points || 0}</span>
                            <span className={styles['images-wrapper']}>
                                <Img src={taooCoinColorReversed} alt="taoo logo" />
                            </span>
                        </div>
                        <p>
                            <FormattedMessage id="referal.total-earned" />
                        </p>
                    </div>
                    <div className="flex-grow-1">
                        <span className={styles.num}>{data?.accepted || 0}</span>
                        <p>
                            <FormattedMessage id="referal.accepted" />
                        </p>
                    </div>
                    <div className="flex-grow-1">
                        <span className={styles.num}>{data?.pending || 0}</span>
                        <p>
                            <FormattedMessage id="referal.pending" />
                        </p>
                    </div>
                </div>
            </div>

            {isLoading && !people && <Loader wrapperStyle={{ marginTop: '2rem' }} />}

            {people && people.length ? (
                <p className={`${ styles.dark } ml-auto`}>
                    <FormattedMessage id="friend-status.total-earned" />
                </p>
            ) : null}
            <ul className={styles.friend_list}>
                {people && people.length ? (
                    people.map(({
                        avatar, name, points, level,
                    }, idx) => (
                        <FriendItem
                            key={idx}
                            image={avatar ? generateImageUrl(avatar) : null}
                            name={name}
                            points={points}
                            level={level}
                        />
                    ))
                ) : (
                    <p>
                        <FormattedMessage id="profile.referrals.no-referrals" />
                    </p>
                )}
            </ul>
        </>
    );
}

export default FriendList;
