import { FormattedMessage } from 'react-intl';

import taooGold from 'assets/Images/taagold.svg';
import Img from 'components/img/Img';

import styles from './index.module.scss';

function FriendItem({
    image, name, level, points,
}) {
    return (
        <li>
            <div className={styles.avatar_container}>
                <div className={styles.avatar_wrapper}>{image && <Img src={image} alt="person avatar" />}</div>
                <div className={styles.avatar_description}>
                    <h6>{name}</h6>
                    <p>
                        <FormattedMessage
                            id="friend-list.level"
                            values={{
                                lvl: level,
                            }}
                        />
                    </p>
                </div>
            </div>
            <div className={styles.points}>
                <span>{points}</span>
                <span className={styles.image_wrapper}>
                    <Img src={taooGold} alt="taoo logo" />
                </span>
            </div>
        </li>
    );
}

export default FriendItem;
