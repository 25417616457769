import { endpoints } from 'constants/api';
import TaooServerClient from 'http/axios';

import * as types from './referral.types';

export const getReferral = () => async function (dispatch) {
    dispatch({ type: types.REFERRAL_GET_REQUEST });

    try {
        const response = await TaooServerClient.get(endpoints.referral.get);

        if (response.status == 200) {
            dispatch(getReferralSuccess(response.data));
        } else {
            dispatch(getReferralError(response));
        }
    } catch (error) {
        dispatch(getReferralError(error));
    }
};

function getReferralSuccess(results) {
    return {
        type:  types.REFERRAL_GET_SUCCESS,
        data:  results,
        error: null,
    };
}

function getReferralError(error) {
    return {
        type: types.REFERRAL_GET_ERROR,
        data: null,
        error,
    };
}
