import { useIntl } from 'react-intl';

import PageTransitionWrapper from 'components/animation-wrapper/page-transition-wrapper';
import TAOOHelmet from 'components/helmet/Helmet';
import SectionNav from 'components/navigations/section-nav';
import useReferral from 'hooks/referral/useReferral';

import FriendList from './friend-list';
import InviteFriend from './invite-friend';

function ProfileReferrals() {
    const intl = useIntl();
    const { data, isLoading } = useReferral();

    return (
        <PageTransitionWrapper>
            <TAOOHelmet title={intl.formatMessage({ id: 'seo.page-title.referrals' })} />

            <SectionNav
                tabs={[
                    {
                        id:    1,
                        label: intl.formatMessage({ id: 'invite-friend' }),
                    },
                    {
                        id:    2,
                        label: intl.formatMessage({ id: 'invitation-status' }),
                    },
                ]}
                tabNodes={{
                    1: <InviteFriend isLoading={isLoading} data={data} />,
                    2: <FriendList isLoading={isLoading} data={data} />,
                }}
            />
        </PageTransitionWrapper>
    );
}

export default ProfileReferrals;
